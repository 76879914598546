.parallax-container {
    height: 100vh;
    overflow: hidden;
    perspective: 1px;
  }
  
  .parallax-content {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: grey;
    text-align: center;
  }
  
  .parallax-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .parallax-content p {
    font-size: 1.5rem;
  }
  