/* Dark Theme Styles */
.notes-form-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #333; /* Dark background */
    color: #fff; /* Light text */
    border-radius: 4px;
  }
  
  .notes-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #fff; /* Ensure labels are also light-colored */
    font-size: 14px;
  }
  
  .notes-form-input {
    width: 100%;
    min-width:500px;
    padding: 8px 12px;
    /* margin-bottom: 15px; */
    background-color: #222; /* Even darker shade for input backgrounds */
    border: 1px solid #555; /* Subtle borders for inputs */
    color: #ddd; /* Lighter text for inputs */
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .notes-form-button {
    background-color: #007bff; /* Bootstrap primary color for contrast */
    border-color: #0056b3;
    color: #fff;
    padding: 10px 16px;
    border-radius: 3px;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    transition: background-color 0.15s ease-in-out;
  }
  
  .notes-form-button:hover {
    background-color: #0056b3;
  }
  