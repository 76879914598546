.footer {
  background-color: black;
  color: white;
  padding: 70px 0;
  margin: 0;
}

.footer-row {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: left;
}

.footer-text {
  font-size: 14px;
  text-align: left;
}
.footer-text a {
  color: white;
} 

.footer-column {
  text-align: left;
}
.footer-column p 
{
  margin-left:0;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.social-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  font-size: 24px;
}
