.row-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 768px) {
    .row-split {
      flex-direction: column;
    }
    .image-left {
      flex-direction: none;
    }
    
    .image-right {
      flex-direction: none;
    }
  
  }
  @media (min-width: 769px) {
    
    .image-left {
      flex-direction: row;
    }
    
    .image-right {
      flex-direction: row-reverse;
    }
    
  }
  
  .image-container {
    flex: 1;
    padding: 20px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
  }
  
  .content-container {
    flex: 1;
    padding: 20px;
  }
  
  .content-container h2 {
    font-size: 3rem;
    font-weight: bold;
  
  }
  
  .content-container p {
    margin-bottom: 10px;
    font-size: 1.3rem;
  }
  p{
    margin:30px;
    text-align: left;
  }
  
  .content-container button {
    margin-top: 10px;
  }
  
  .button {
    
    background-color: lightslategray;
   
  }