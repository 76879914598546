.rowSquare {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

  }
  
  .square {

    /* flex: 0 0 45%; */
  width: 45%;
  max-width: 48%;
  position: relative;
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  }
  
  
  .content {
    position: relative;
    width:100%;

    min-width: 100%;
    overflow: hidden;
  }
  
  
  .image {
    width: 100%;
    height: auto;
   
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 255, 0.7);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

  
  }
  
  .overlay-content {
    text-align: left;
    font-size: 1.3rem;
  }
  
  .square:hover .overlay {
    transform: translateX(0);
  }

  /* media query for mobile */
  @media (max-width: 768px) {
    .square {
    /* flex: 0 1 auto; */
    max-width: 100%;
    width:100%;
    position:unset;
   
    }
    .content{
       min-width:100%;
       margin:0 0 0  0;
       padding: 0 0 0 0;
    }
}
  