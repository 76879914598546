.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    background:#343838;
    color:white;
  }
  .logo{width:280px;min-width:280px;}
  .logo img {
    width: 280px;
    height: auto;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    height:50px;
    text-align: center;
   
  }
  .contact-item p{
    text-align: center;
    width:100%;
  }
  
  .contact-icon {
    margin-right: 5px;
  }

  /* media query for small screens */
@media screen and (max-width: 720px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-info {
      margin-top: 40px;
    }
  
    .contact-item {
      margin-bottom: 20px;
      
    }
    .contact-item p{
        text-align: center;
        width:100%;
        font-size: 1.2rem;
      }
  }
  