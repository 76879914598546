.static-background {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 900px;
    /* background-image: url("https://i.ibb.co/TgYDkdQ/HOMEPAGE-CB-Wholesale-Electric-Lighting-820x1640-1to2.webp"); */
    background-size: cover;
    background-position: center;
    z-index: -1;
    background-color: #282c34;
    padding:10px;
  }
  
  .background-content {
    position: fixed;
    top: 10%;
    /* left: 50%; */ 
    width:100%;
    /* transform: translate(-50%, -0%); */
    text-align: center;
    color: #fff;
    
  }
  
  .background-content h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top:1.5rem;
  }
  
  .background-content p {
    font-size: 1.5rem;
    text-align: center;
  }
  
  @media (max-width: 768px) {

    .background-content h1 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      text-align: center;
      margin-top:1.5rem;
    }
    .background-content p {
      font-size: 1rem;
      text-align: center;
    }
    
  }