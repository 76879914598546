
  

  .target-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .target-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  