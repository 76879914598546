.background-video {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
   
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .contentVideo {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-0%, -50%);
    text-align: center;
    
    max-width: 80%;
    text-align: left;
    /* font-family: "Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif; */
   font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    -webkit-font-smoothing: antialiased;
   
  }
  
  .background-video-title {
    font-size: 3rem;
    color: #fff;
    padding:20px;
    /* background-color: lightslategray; */
    background-color: grey;
    opacity: .8;
    border-radius: 15px;
    display: inline-block;
   
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: .7rem;
    margin-left:0;
    padding:20px;
    max-width: 60%;
    background-color: grey;
    opacity: .8;
    border-radius: 15px;
   
  }
  
  .button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
   margin-left:0px;
  }
  .buttonRed {
    
    background-color: #C22130;
    color: #fff;
    border: none;
    border-radius: 5px;
   margin-left:0px;
  }
  
  @media (max-width: 768px) {
    .contentVideo {
      width: 100%;
      
      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        
        max-width: 100%;
        text-align: center;
        background:none;
       
      
      
    }
    
    .background-video-title {
      font-size: 3rem;
      width:100%;
      text-align: center;
      
    opacity: .8;
    border-radius: 15px;
    }
    
    .subtitle {
      font-size: 1.2rem;
      text-align: center;
      width: 100%;
      max-width: 100%;
     
      opacity: .8;
      border-radius: 15px;
    }
    
    .button {
      padding: 1rem 2rem;
      font-size: 1.5rem;
      text-align: center;
    }
  }