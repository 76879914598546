html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevents horizontal scroll */
  
}

*, *:before, *:after {
  box-sizing: inherit; /* Inherit box-sizing to make sure padding and border do not affect the width */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.customContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  grid-gap: 20px; /* Gap between columns */
 
  place-items: center; /* Center the content horizontally and vertically */

}

.customColumn {
  /* Styles for columns */
  text-align: center;
}
.b-logistics-text
{
  font-size: 1.5rem;
  font-weight: 300;
  
  text-align: center;
  padding-bottom: 1rem;
}

.button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
}

.content-container h2 {
  font-size: 3rem;
  font-weight: bold;

}

.add-to-cart-button {
  background-color: #002a6a;
}

.add-to-cart-button:hover {
  background-color: #0040a6; /* Adjust the color as desired */
}

/* Temporary Brand Colors */

.cb-blue {
  color: #002a6a;
}
.bg-cb-blue{
  background: #30476D;
}

.bg-black-water {
  background-color: #08282e;
}

.text-black-water {
  color: #08282e;
}

.bg-deep-water {
  background-color: #002a6a;
}

.text-deep-water {
  color: #002a6a;
}

.bg-shallow-water {
  background-color: #0b90cb;
}

.text-shallow-water {
  color: #0b90cb;
}

.bg-gray-rgb {
  background-color: #a5a5a5;
}

.text-gray-rgb {
  color: #a5a5a5;
}

.bg-tan-rgb {
  background-color: #b19f4d;
}

.text-tan-rgb {
  color: #b19f4d;
}

.bg-dirty-rgb {
  background-color: #877f61;
}

.text-dirty-rgb {
  color: #877f61;
}

.bg-earthy-green {
  background-color: #84954b;
}

.text-earthy-green {
  color: #84954b;
}

.bg-dark-brown {
  background-color: #3d3434;
}

.text-dark-brown {
  color: #3d3434;
}

.bg-yellow-rgb {
  background-color: #f7cc00;
}

.text-yellow-rgb {
  color: #f7cc00;
}
