p 
{
    font-size: 1.3rem;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-gif {
    width: 300px;
    height: 100px;
  }

  .target-section {
    opacity: .2;
    margin-top: 3em;
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.target-section.animate {
    margin-top:1em;
    opacity: 1;
    transition: opacity 1.5s ease, transform 1.5s ease;
    -webkit-transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -o-transition: 1.5s ease-in-out;
    transition: 1.5s ease-in-out;
    -webkit-transform: translate(3em, 0);
    -moz-transform: translate(3em, 0);
    -o-transform: translate(3em, 0);
    -ms-transform: translate(3em, 0);
    transform: translate(3em, 0);
}

.contractors
{
  
    background: white;
    width: 80%;
    margin:auto;

 
}

/* media query for mobile */
@media only screen and (max-width: 600px) {
    .contractors
    {
        width: 100%;
    }
  }
  /* media query for tablet */