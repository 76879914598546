.s-gallery-item {
    position: relative;
  }
  
  .s-gallery-item img {
    width: 100%;
    display: block;
    border-radius: 0.25rem; /* Optional for rounded corners */
  }
  
  .s-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    margin:0;
    padding:0;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for text visibility */
    color: #fff;
    padding: 1rem; /* Adjust as needed */
    border-radius: 0.25rem; /* Optional for rounded corners */
    transition: opacity 0.3s ease;
    opacity: .7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:100%;
  }
  
  .s-gallery-item:hover .overlay {
    opacity: 1; /* Show the overlay on hover */
  }
  
  .s-text {
    text-align: center;
    font-size: 1.5rem; /* Adjust as needed */
  }
  
  .gallery-background {
    position: relative;
    background-image: url('https://belcastro.s3.amazonaws.com/background-globe.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    padding-top:30px;
    padding-bottom:30px;
    /* opacity: 0.5; Adjust as needed */
  }
  
  .gallery-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* white background coloe */
    background-color: rgba(255, 255, 255, 0.7); /* Light overlay for text visibility */
    
    z-index: 0; /* Ensure this is below your content */
  }
  
  /* Add or adjust other styles for .s-gallery-item, .s-overlay, .s-text as needed */
  