.banner-container {
    position: relative;
    width: 100%;
    margin: 0;
    height:300px;
    overflow: hidden;
    
  }
  
  .banner-image {
    position: relative;
    text-align: center;
  }
  
  .banner-image img {
    width: 100%;
    height: auto;
    size:cover;
  }
  
  .banner-text {
    background-color: grey;
    padding:10px;
    border-radius: 15px;
    position: absolute;
    top:240px;
    left: 10px;
    font-size: 2rem;
    line-height: 1;
    opacity: .8;
    /* transform: translate(-0%, -50%); */
    color: #fff; /* Choose a text color that stands out against your banner */
  }
  
  /* media query */
    @media (max-width: 768px) {
        .banner-text {
        font-size: 1.5rem;
        top: 150px;
        }
    }