.sticker-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1010;
    background-color: black;
    color: white;
  }
  
  .sticker-header.active {
    background-color: #333; /* Background color when menu is active */
  }
  
  .logo {
    width: 50px; /* Adjust the width as needed */
    height: auto;
    margin-right: 10px;
  }
  
  .navbar {
    /* padding: 10px 0; */
  }
  
  .navbar-nav .nav-link {
    color: white;
    /* margin-right: 15px; */
  }
  
  .navbar-toggler {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 20px;
  }
  