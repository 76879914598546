.header {
    position: relative;
  }
  
  .header-image {
    width: 100%;
    height: auto;
    height:500px;
  }
  
  .header-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  
  .header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
  }
  
  .header-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .header-subtitle {
    font-size: 1.5rem;
  }
  