.catalog-table {
    width: 100%;
    margin-top: 20px;
  }
  
  .table .inventory-table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }
  
  .btn {
    margin-top: 10px;
  }
  
  .text-muted {
    font-size: 0.875rem;
  }
  
  strong {
    font-weight: bold;
  }
  
  .bottom-spacer {
    margin-bottom: 10px;
  }
  