ul.no-bullet {
    list-style-type: none;
    padding: 0;
  }
ul li {
    padding: 0.5rem 0;
    font-size: 1.3rem;
}
/* media query for mobile  */
@media (max-width: 768px) {
    ul li {
        font-size: 1rem;
    }
}