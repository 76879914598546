.catalog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px; /* You had this property twice, you only need it once */
  align-items: stretch; /* Make items have the same height */
  width: 100%; /* Adjust the width as needed */
  max-width: 1200px; /* Optional: Set a maximum width to avoid excessive stretching on larger screens */
  margin: 0 auto; /* Center the container */
  padding-left: 10px;
  padding-right: 10px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) { /* 768px is a common breakpoint for mobile devices */
  .catalog-list {
    width: 100%; /* Make the catalog-list full width */
    max-width: none; /* Remove the maximum width constraint */
    margin: 0; /* Remove horizontal margins if necessary */
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); /* Make sure grid columns take full width */
  }
}

  
  .catalog-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top:10px;
  }
 .bottom-spacer {
   width:100%;
   height:120px;
  }
  .bottom-div {
    
    position: absolute; /* Set the bottom-div as absolute */
    bottom: 0; /* Pin it to the bottom */
    left: 0; /* Adjust horizontal positioning if needed */
    right: 0;
    padding: 5px; /* Add padding for spacing */
    /* background-color: rgba(0, 0, 0, 0.5); Example background color */
    border-top:2px solid #666;
    /* color: white; Example text color */
  }
  
  .item-title {
    font-size: 1.2rem;
    margin: 0;
    padding:8px;
  }
  
  .item-sku {
    /* color: #666; */
  }
  
  .item-received,
  .item-available,
  .item-on-hand {
    
    margin-top:0;
    margin-bottom:0;
  }
  .item-on-hand
  {
    font-weight: bold;
  }
  .item-p{
    margin-top:0;
    margin-bottom:0;
  }
  
  .item-reference {
    color: #888;
  }

  .item-small-spacer{
    width:100%;
    height:12px;
  }

  
  