.customer-profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.profile-card {
  width: 250px; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  border: 2px solid #ccc;
  padding: 12px; /* Adjust padding as needed */
  margin: 8px;
  font-size: 12px; /* Smaller text size */
  line-height: 1.1; /* Smaller line height */
  cursor: pointer;
}

.profile-card.selected {
  border: 2px solid red !important; /* Change this to your preferred color */
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust height as needed */
  margin-bottom: 12px; /* Adjust margin as needed */
}

.logo-container img {
  max-height: 80%; /* Smaller max-height */
  max-width: 80%; /* Smaller max-width */
}

.info-container p {
  margin: 2px 0; /* Adjust margins for smaller spacing */
}

.border {
  border-radius: 8px;
}

.text-left {
  text-align: left;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
